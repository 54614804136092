.checkout-wrapper {
    min-height: calc(100vh - (46px * 2) - 37px);
}

.checkout-container {

}

.empty-checkout {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - (46px * 2) - 37px);
}

.empty-checkout .client__title {
    margin-bottom: 16px;
}
.empty-checkout .checkout-description {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 60px;
}
.empty-checkout .checkout-button__continue-shopping {
    height: 32px;
    padding: 6px 15px;
    border: 1px solid;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}

.checkout-container {
    display: flex;
    justify-content: space-between;
}
.checkout-container .left,
.checkout-container .right {
    width: 42%;
}

.part {
    margin-bottom: 28px;
}
.part h3 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 12px;
}
.part input,
.part select {
    width: 100%;
    height: 42px;
    flex-shrink: 0;
    padding: 12px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    border-radius: 4px;
    border: 1px solid #A4A2A2;
    margin-bottom: 10px;
    outline: none !important;
}
.part select {
    padding-right: 16px;
    padding-left: 10px;
}
.part .double {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}
.part .double input {
    width: calc(50% - 6px);
}
.part .add-details {
    height: 42px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
}
.part .add-details .click-me {
    height: 42px;
    display: flex;
    align-items: center;

}
.part .add-details svg {
    margin-right: 3px;
}
.part .add-details input {
    margin-bottom: 0;
}
.part input.error,
.part select.error {
    border-color: red;
}

.checkout-container .pay {
    border-radius: 4px;
    background: #000;
    height: 42px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    cursor: pointer;
    text-transform: initial;
}
.checkout-container .pay:hover {
    background-color: #222;
}
.billing-address button,
.shipping-method button {
    width: 100%;
    border: 1px solid #000;
    background-color: #60606000;
    color: #000;
    font-size: 14px;
    padding: 12px;
    height: 42px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.billing-address button:nth-of-type(2),
.shipping-method button:nth-of-type(2) {
    border-radius: 0 0 4px 4px;
}
.billing-address button:nth-of-type(1),
.shipping-method button:nth-of-type(1) {
    border-radius: 4px 4px 0 0;
    border-bottom: none;
}
.billing-address button.active,
.billing-address button:hover,
.shipping-method button.active,
.shipping-method button:hover {
    background-color: #F6F6F6;
}

.billing-details {
    border-radius: 0px 0px 6px 6px;
    border: 1px solid #DEDEDE;
    background: #F6F6F6;
    padding: 12px;
}
.billing-details .phone {
    margin-bottom: 0;
}
.payment-popup {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    opacity: 0;
    transition: .3s;
}

.payment-popup.active {
    opacity: 1;
    pointer-events: initial;
    z-index: 1;
}

.payment-popup button.back {
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: 1;
    cursor: pointer;
    border: 1px solid #000;
    padding: 12px;
    font-size: 16px;
}
@media( max-width: 769px ) {
    .checkout-container {
        flex-direction: column;
        justify-content: flex-start;
    }
    .checkout-container .left,
    .checkout-container .right {
        width: 100%;
    }
    .checkout-container .double {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
    .checkout-container .double input {
        width: 100%;
    }
    .part h3 {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .part input {
        color: rgba(58, 58, 58, 0.80);
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .billing-address button, .shipping-method button {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
}
