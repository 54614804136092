.container-login100 .txt1 {
    font-family: 'Lexend', sans-serif;
    font-size: 13px;
    color: #e5e5e5;
    line-height: 1.5
}

.container-login100 .limiter {
    width: 100%;
    margin: 0 auto
}

.container-login100 {
    margin: -46px;
    width: calc(100% + 92px);
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    z-index: 1;
    background-image: url("../../assets/bannerFullSize.jpg");
}

.container-login100::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255,.6)
}

.wrap-login100 {
    width: 350px;
    /*border-radius: 10px;*/
    overflow: hidden;
    padding: 45px 35px;
    background: #fff;
    position: relative;
}

.login100-form {
    width: 100%
}

.login100-form.error .wrap-input100 {
    border-color: red;
}

.login100-form-logo {
    font-size: 60px;
    color: #333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #000;
    margin: 0 auto 20px;
    background-image: url("../../assets/product1.jpg");
    background-size: 70%;
    background-position: center;
    background-repeat: no-repeat;
}

.login100-form-title {
    font-family: 'Lexend', sans-serif;
    font-size: 30px;
    color: #000;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;
    display: block
}

.wrap-input100 {
    width: 100%;
    position: relative;
    border: 1px solid transparent;
    border-bottom: 2px solid rgba(255,255,255,.24);
    margin-bottom: 30px;
    background-color: rgb(0 0 0 / 4%);
}

.wrap-input100 .icon {
    width: 30px;
}

.input100 {
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    color: #000;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: 0 0;
    padding: 0 5px 0 38px;
    transition: 0.4s padding;
    border: none;
    outline: none;
}



.focus-input100 {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none
}

.focus-input100::before {
    content: "";
    display: block;
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    background: #000
}

.focus-input100::after {
    font-family: 'Lexend', sans-serif;
    font-size: 22px;
    color: #000;
    content: url("../../assets/admin/user.svg");
    display: block;
    max-width: 25px;
    max-height: 25px;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    padding-left: 5px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

.focus-input100.pass::after {
    content: url("../../assets/admin/pass.svg");
    top: 46%;
}

.input100:focus {
    padding-left: 5px
}

.input100:focus+.focus-input100::after {
    top: 0;
    font-size: 18px
}

.input100:focus+.focus-input100.pass::after {
    top: -2px;
}

.input100:focus+.focus-input100::before {
    width: 100%
}

.has-val.input100+.focus-input100::after {
    top: -22px;
    font-size: 18px
}

.has-val.input100+.focus-input100::before {
    width: 100%
}

.has-val.input100 {
    padding-left: 5px
}

.contact100-form-checkbox {
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 35px
}

.input-checkbox100 {
    display: none
}

.label-checkbox100 {
    font-family: 'Lexend', sans-serif;
    font-size: 13px;
    color: #000;
    line-height: 1.2;
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer
}

.label-checkbox100::before {
    content: '\f26b';
    font-family: 'Lexend', sans-serif;
    font-size: 13px;
    color: transparent;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background: #000;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.input-checkbox100:checked+.label-checkbox100::before {
    color: #555
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.login100-form-btn {
    cursor: pointer;
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    line-height: 1.2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 120px;
    height: 50px;
    border-radius: 25px;
    background: #000;
    color: #fff;
    position: relative;
    z-index: 1;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

.login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: #000;
    top: 0;
    left: 0;
    opacity: 1;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

.login100-form-btn:hover {
    color: #dcdcdc;
}

.login100-form-btn:hover:before {
    opacity: 0
}

@media (max-width: 768px) {
    .container-login100 {
        margin: -20px;
        width: calc(100% + 40px);
    }
}

.validate-input {
    position: relative
}

.login100-form.error .validate-input.pass {
    margin-bottom: 0;
}

.login100-form.error .error-message {
    margin-top: 5px;
    color: red;
    margin-bottom: 9px;
    font-size: 12px;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #000;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
    pointer-events: none;
    font-family: 'Lexend', sans-serif;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .4s;
    -o-transition: opacity .4s;
    -moz-transition: opacity .4s;
    transition: opacity .4s
}

.alert-validate::after {
    content: '\f12a';
    font-family: 'Lexend', sans-serif;
    font-size: 16px;
    color: #c80000;
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 5px
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1
}

@media(max-width: 992px) {
    .alert-validate::before {
        visibility:visible;
        opacity: 1
    }
}
