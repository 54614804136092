a {
    color: #000000;
    text-decoration: none;
}

.breadCrumbs {
    font-weight: 500;
    margin-top: 43px;
    margin-bottom: 32px;
}
.breadCrumbs .no-link {
    color: #000;
}

.homeBtn {
    font-weight: 400;
}

.homeBtn img {
    width: 100px;
}
@media (max-width: 769px) {
    .breadCrumbs {
        margin-top: 50px;
        margin-bottom: 16px;
    }
    .homeBtn img {
        width: 80px;
    }
}
