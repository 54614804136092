.menu__container {
    min-width: 200px;
    border-right: 1px solid gray;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.menu__container .menu__container-link {
    margin-bottom: 10px;
    display: flex;
}
.menu__container .menu__container-link.solo {
    background-color: #e9e9e9;
    padding: 10px;
    border-radius: 10px;
    gap: 10px;
    align-items: center;
}
.menu__container .menu__container-link.solo.active {
    background-color: #000;
    color: #fff;
}

.menu__container .menu__container-link:hover {
    cursor: pointer;
}

.menu__container .menu__container-link .link {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    background-color: #e9e9e9;
    width: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px
}

.menu__container .menu__container-link .link:hover {
    background-color: #e5e5e5;
}

.menu__container .menu__container-link .link.active {
    background-color: #000;
    color: #fff;
}

.menu__container .link__container .link-title,
.menu__container .link__container h5 {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    font-weight: bold;
}

.menu__container .link__container .link-title:hover {
    text-decoration: underline;
    cursor: pointer;
}

.menu__container .link__container {
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    transition: .3s;
}

.menu__container .link__container .menu__container-link{
    justify-content: space-between;
}
.menu__container .link__container .menu__container-link .button {
    height: 43.8px;
    background: #6cc454;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px
}

.menu__container .link__container .menu__container-link .button:hover {
    background: #75d25c;
}

.menu__container .link__container.active {
    background-color: #000;
}
.menu__container .link__container.active .link-title {
    color: #fff;
}

@media (max-width: 768px) {
    .menu__container {
        min-width: initial;
    }
    .menu__container .menu__container-link {
        font-size: 0;
        display: initial;
    }
    .menu__container a i {
        font-size: initial;
    }
}
