.utp {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    padding: 240px 0;
}

.utp h1 {
    margin-top: 0;
    white-space: nowrap;
    text-align: right;
    color: black;
    font-size: 60px;
    font-weight: 300;
    word-wrap: break-word
}

.utp p {
    margin-top: 150px;
    margin-bottom: 0;
    color: black;
    font-size: 18px;
    font-weight: 400;
    max-width: 50%;
    font-style: normal;
    line-height: normal;
}

.utp p span {
    font-weight: 600;
}

@media (max-width: 769px) {
    .utp {
        padding: 160px 0;
        display: block;
    }
    .utp h1 {
        font-size: 24px;
        font-weight: 400;
        margin: 0;
    }
    .utp p {
        max-width: initial;
        margin: 24px 0 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
