.custom-radio {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
.custom-radio input {
    position: absolute;
    pointer-events: none;
    opacity: 0;
}
.custom-radio .custom-radio__switch {
    min-width: 12px;
    min-height: 12px;
    border: 1px solid;
    margin: 6px;
    position: relative;
    border-radius: 12px;
}
.custom-radio .custom-radio__switch::before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    min-width: 8px;
    min-height: 8px;
    border-radius: 10px;
    background-color: transparent;
    transition: .3s;
}
.custom-radio.checked .custom-radio__switch::before {
    background-color: black;
}
