.loader__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    opacity: 0;
    transition: 0.3s opacity;
    z-index: 1;
    pointer-events: none;
}

.loader__wrapper::after {
    content: "";
    position: absolute;
    left: 6%;
    right: 6%;
    top: 5%;
    bottom: 5%;
    /*border-radius: 5px;*/
    border: 1px solid;
}

.loader__wrapper.active {
    opacity: 1;
    pointer-events: initial;
}
